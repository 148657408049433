import { all, takeEvery, call, put, select } from 'redux-saga/effects'
import { message } from 'antd'
import { uploadFile } from 'services/virtual/general'
import { completeRegistration, validateEmail, validatePhone } from 'services/virtual/user'
import actions from './actions'

export function* UPLOAD_AVATAR(data) {
  try {
    const config = {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
        data.payload.onProgress({ percent: percentCompleted })
      },
    }
    const result = yield call(uploadFile, { file: data.payload.file, config })
    if (result.status === 200) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          avatarType: 'image',
          avatarData: result.data.fileUrl,
          imageURL: result.data.fileUrl,
        },
      })
      data.payload.onSuccess('ok')
      message.success('Image Uploaded Successfully')
    } else {
      data.payload.onError({ event: result.statusText })
      message.error(
        'Error occured in uploading the Image. Kindly Choose a normal Avatar as of now.',
      )
    }
  } catch (err) {
    data.payload.onError({ event: err.response.data.message })
    console.log(err.response)
    message.error(err.response.data.message)
  }
}

export function* COMPLETE_REGISTRATION(data) {
  try {
    const state = yield select()
    const {
      registerVirtualUser: {
        additionalDetails,
        firstName,
        lastName,
        email,
        mobile,
        password,
        organization,
        avatarType,
        avatarData,
        // current,
        // country,
        city,
        department,
        mystate,
        category
      },
    } = state
    const finalValue = {
      additionalDetails,
      firstName,
      lastName,
      email,
      password,
      organization,
      // country,
      city,
      department,
      mobile,
      mystate,
      category,
      avatar_type: avatarType,
      avatar_data: avatarType === 'text' ? data.payload.avatarData : avatarData,
      avatar_color: data.payload.avatarColor,
    }

    console.log("Checking this once")
    console.log(finalValue)
    const result = yield call(completeRegistration, { registration: finalValue })
    if (result.status === 200) {
      yield put({
        type: 'virtaluser/LOGIN',
        payload: {
          numberOrEmail:email,
          password: '123456',
        },
      })
      // const newCurrent = current + 1

      // yield put({
      //   type: actions.SET_STATE,
      //   payload: {
      //     avatarData: avatarType === 'text' ? data.payload.avatarData : avatarData,
      //     avatarColor: data.payload.avatarColor,
      //     loading: false,
      //     current: newCurrent,
      //     userID: result.data.user_id,
      //   },
      // })
      message.success('Registration Successful. Press on the login link to login into the platform.')
    } else {
      message.error(`${result.status}: ${result.statusText} - Error Occurred`)
    }
  } catch (err) {
    console.log(err.response)
    message.error(err.response.data.message)
  }
}

export function* VALIDATE_EMAIL(data) {
  try {
    const dataFinal = {
      email: data.payload.email,
    }
    const result = yield call(validateEmail, { finalData: dataFinal })
    if (result.status === 200) {
      if (result.data.success) {
        // const patt = new RegExp("^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(ey|wiztales)\.com$");
        // if(patt.test(data.payload.email.toLowerCase()))
        // {
        yield put({
          type: actions.SET_STATE,
          payload: {
            emailStatus: 'success',
          },
        })
        // }
        // else{
        //   yield put({
        //     type: actions.SET_STATE,
        //     payload: {
        //       emailStatus: 'domainerror',
        //     },
        //   })
        // }

      } else {
        yield put({
          type: actions.SET_STATE,
          payload: {
            emailStatus: 'error',
          },
        })
      }
    } else {
      yield put({
        type: actions.SET_STATE,
        payload: {
          emailStatus: 'warning',
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    message.error(err.response.data.message)
  }
}

export function* VALIDATE_PHONE(data) {
  try {
    const dataFinal = {
      number: data.payload.phone,
    }
    const result = yield call(validatePhone, { finalData: dataFinal })
    if (result.status === 200) {
      if (result.data.success) {
        yield put({
          type: actions.SET_STATE,
          payload: {
            phoneStatus: 'success',
          },
        })
      } else {
        yield put({
          type: actions.SET_STATE,
          payload: {
            phoneStatus: 'error',
          },
        })
      }
    } else {
      yield put({
        type: actions.SET_STATE,
        payload: {
          phoneStatus: 'warning',
        },
      })
    }
  } catch (err) {
    console.log(err.response)
    message.error(err.response.data.message)
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.UPLOAD_AVATAR, UPLOAD_AVATAR),
    takeEvery(actions.COMPLETE_REGISTRATION, COMPLETE_REGISTRATION),
    takeEvery(actions.VALIDATE_EMAIL, VALIDATE_EMAIL),
    takeEvery(actions.VALIDATE_PHONE, VALIDATE_PHONE),
  ])
}
